import request from '@/plugins/axios'

// 平台配置

// 获取平台信息
export const apiBaseConfig = () => request.get('/settings.platform/getBaseConfig')

// 设置平台信息
export const apiBaseConfigEdit = (params: any) => request.post('/settings.platform/setBaseConfig', params)

// 获取平台备案信息
export const apiRecordConfig = () => request.get('/settings.platform/getRecordConfig')

// 设置平台备案信息
export const apiRecordConfigEdit = (params: any) => request.post('/settings.platform/setRecordConfig', params)

// 系统设置

// 获取系统日志列表
export const apiSystemlogList = (params: any) => request.get('/settings.log/lists', { params })

// 清除系统缓存
export const apiSystemCacheClear = () => request.post('/settings.cache/clear')

// 定时任务列表
export const apiCrontabLists = (params: any) => request.get('/crontab.crontab/lists', { params })

// 添加定时任务
export const apiCrontabAdd = (params: any) => request.post('/crontab.crontab/add', params)

// 查看详情
export const apiCrontabDetail = (params: any) => request.get('/crontab.crontab/detail', { params })

// 编辑定时任务
export const apiCrontabEdit = (params: any) => request.post('/crontab.crontab/edit', params)

// 删除定时任务
export const apiCrontabDel = (params: any) => request.post('/crontab.crontab/delete', params)

// 获取规则执行时间
export const apiCrontabExpression = (params: any) => request.get('/crontab.crontab/expression', { params })

// 操作定时任务
export const apiSrontabOperate = (params: any) => request.post('/crontab.crontab/operate', params)

/** E 系统维护 **/

/** S 系统更新 **/
// 系统更新列表
export const apiSystemUpgradeLists = (params: any) => request.get('/settings.upgrade/lists')

// 下载更新包
export const apiSystemUpgradeDownloadPkg = (params: any) => request.post('/settings.upgrade/downloadPkg', params)

// 一键更新
export const apiSystemUpgrade = (params: any) => request.post('/settings.upgrade/upgrade', params)
/** E 系统更新 **/

// 系统环境
export const apiSystemSystemEnv = () => request.get('/settings.env/systemEnv')

/** S 客服设置 **/
// 客服获取
export const apiServiceGet = () => request.get('/settings.service/getConfig')

// 客服设置
export const apiServiceSet = (params: any) => request.post('/settings.service/setConfig', params)
/** E 客服设置 **/

/** S 安全设置 **/
// 修改管理员密码
export const apiResetPassword = (params: any) => request.post('/Login/resetPassword', params)
/** E 安全设置 **/

/** S 存储设置 **/
// 获取存储引擎列表
export const apiStorageList = (): Promise<any> =>
  request.get('/settings.Storage/lists')

// 获取存储配置信息
export const apiStorageIndex = (params: any): Promise<any> =>
  request.get('/settings.Storage/index', { params })

// 更新配置
export const apiStorageSetup = (params: any): Promise<any> =>
  request.post('/settings.Storage/setup', params)

// 切换默认存储引擎
export const apiStorageChange = (params: any): Promise<any> =>
  request.post('/settings.Storage/change', params)
/** E 存储设置 **/

/** S 支付设置 **/
// 支付查看
export const apiGetPay = (params: any) => request.get('/settings.platform/getPay', { params })

// 支付编辑
export const apiSetPay = (params: any) => request.post('/settings.platform/setPay', params)
/** E 支付设置 **/

/** S 物流设置 **/
// 物流接口查看
export const apiGetLogisticsConfig = (params: any) => request.get('/settings.platform/getLogisticsConfig', { params })

// 物流接口设置
export const apiSetLogisticsConfig = (params: any) => request.post('/settings.platform/setLogisticsConfig', params)
/** E 物流设置 **/

/** S 快递管理 **/
// 快递列表
export const apiExpressLists = (params: any) =>
  request.get('/order.order/listexpress', { params })

// 快递详情
export const apiExpressDetail = (params: any) =>
  request.get('/order.order/detailexpress', { params })

// 快递添加
export const apiExpressAdd = (params: any) =>
  request.post('/order.order/addexpress', params)

// 快递编辑
export const apiExpressEdit = (params: any) =>
  request.post('/order.order/editexpress', params)

// 快递删除
export const apiExpressDel = (params: any) =>
  request.post('/order.order/delexpress', params)

// 
export const apiSwitchShop = (params: any) =>
  request.post('/order.order/switchShop', params)

// 快递国家列表
export const apiCountryList = (params: any) =>
  request.get('/order.order/getCountry', { params })

// 快递规则设置
export const apiSetRule = (params: any) =>
  request.post('/order.order/setRule', params)

export const apiGetRule = (params: any) =>
  request.get('/order.order/getRule', { params })

// 快递规则查看
export const apiRuleInfo = (params: any) =>
  request.get('/order.order/getRule', { params })

export const apiEngineList = (params: any) =>
  request.get('/settings.express.account/engineList', { params })

export const apiEngineField = (params: any) =>
  request.get('/settings.express.account/engineField', { params })


export const apiAccountAdd = (params: any) =>
  request.post('/settings.express.account/add', params)

export const apiAccountEdit = (params: any) =>
  request.post('/settings.express.account/edit', params)

export const apiAccountStatus = (params: any) =>
  request.post('/settings.express.account/del', params)

export const apiEngineDetail = (params: any) =>
  request.get('/settings.express.account/detail', { params })

export const apiEngineDel = (params: any) =>
  request.get('/settings.express.account/del', { params })


export const apiEngineAccountLists = (params: any) =>
  request.get('/settings.express.account/lists', { params })

export const apiProductList = (params: any) =>
  request.post('/settings.express.account/productList', params)
/** E 快递管理 **/

/** S 短信设置 **/

// 短信接口查看
export const apiGetSmsConfig = (params: any) => request.get('/settings.platform/getSmsConfig', { params })

// 短信接口设置
export const apiSetSmsConfig = (params: any) => request.post('/settings.platform/setSmsConfig', params)

/** E 物流设置 **/

// 汇率设置
export const apiSetMoney = (params: any) =>
  request.post('/order.order/setmoney', params)

// 汇率查看
export const apiMoneyInfo = (params: any) =>
  request.get('/order.order/getMoney', { params })


export const apiFaceSheetTemplateLists = (params: any) =>
  request.get('/express_assistant.face_sheet_template/lists', { params })

export const apiFaceSheetTemplateAdd = (params: any) =>
  request.post('/express_assistant.face_sheet_template/add', params)

export const apiFaceSheetTemplateEdit = (params: any) =>
  request.post('/express_assistant.face_sheet_template/edit', params)

export const apiFaceSheetTemplateDetail = (params: any) =>
  request.get('/express_assistant.face_sheet_template/detail', {params})


export const apiFaceSheetTemplateDel = (params: any) =>
  request.post('/express_assistant.face_sheet_template/delete', params)

export const apiExpressList = (params: any) =>
  request.post('/express_assistant.face_sheet_template/getExpressLists', params)

export const apiGetFaceSheetPayment = (params: any) =>
  request.post('/express_assistant.face_sheet_template/getPayTypeLists', params)


 
export const apiGetFactoryLogisticsConfig = (params: any) =>
request.get('/factory.factory/getexpresshundred', {params})


export const apiSetFactoryLogisticsConfig = (params: any) =>
request.post('/factory.factory/setexpresshundred', params)


// 百度AI
// 获取
export const apiSettingsShopShopSettingGetbaiduConfig = () => request.get('settings.platform/getbaiduConfig')
// 设置
export const apiSettingsShopShopSettingSetbaiduConfig = (data: any) => request.post('settings.platform/setbaiduConfig', data)

//商户百度ai图片充值配置
//获取
export const apiSettingsPlatformGetBaiDuAiChargeConfig = () => request.get('settings.platform/getbaiduaichargeConfig')
//设置
export const apiSettingsPlatformSetBaiDuAiChargeConfig = (data: any) => request.post('settings.platform/setbaiduaichargeConfig', data)

//商户左糖ai图片充值配置
//获取
export const apiSettingsPlatformGetZuoTangConfig = () => request.get('settings.platform/getzuotangConfig')
//设置
export const apiSettingsPlatformSetZuoTangConfig = (data: any) => request.post('settings.platform/setzuotangConfig', data)
//商户图可丽ai图片充值配置
//获取
export const apiSettingsPlatformGetTuKeLiConfig = () => request.get('settings.platform/gettukeliConfig')
//商户图可丽ai图片充值配置
//设置
export const apiSettingsPlatformSetTuKeLiConfig = (data: any) => request.post('settings.platform/settukeliConfig', data)
//temu列表
export const apiSettingsExpressTemuLists = (params: any) => request.get('settings.express.temu/lists', { params })
//temu列表
export const apiSettingsExpressTemuDetail = (params: any) => request.get('settings.express.temu/detail', { params })
//temu类型列表
export const apiSettingsExpressTemuEngineList = (params: any) => request.get('settings.express.temu/engineList', { params })
//temu新增
export const apiSettingsExpressTemuAdd = (data: any) => request.post('settings.express.temu/add', data)
//temu编辑
export const apiSettingsExpressTemuEdit = (data: any) => request.post('settings.express.temu/edit', data)
//temu删除
export const apiSettingsExpressTemuDel = (data: any) => request.post('settings.express.temu/del', data)
//temu 地区列表
export const apiSettingsExpressTemuRegionLists = (params: any) => request.get('settings.express.temu_region/lists', { params })
//temu 地区列表删除
export const apiSettingsExpressTemuRegionDel = (data: any) => request.post('settings.express.temu_region/del', data)
//temu 地区列表新增
export const apiSettingsExpressTemuRegionAdd = (data: any) => request.post('settings.express.temu_region/add', data)
//temu 地区列表编辑
export const apiSettingsExpressTemuRegionEdit = (data: any) => request.post('settings.express.temu_region/edit', data)
//temu 地区列表详情
export const apiSettingsExpressTemuRegionDetail = (data: any) => request.post('settings.express.temu_region/detail', data)

// 刊登

// 大洲列表
export const apiTemplateAmazonSiteDepartment = (params: any) => request.get('template.amazonSite/department', { params })
// 站点列表
export const apiTemplateAmazonSiteLists = (params: any) => request.get('template.amazonSite/lists', { params })
// 站点详情
export const apiTemplateAmazonSiteDetail = (params: any) => request.get('/template.amazonSite/detail', { params })
// 站点编辑
export const apiTemplateAmazonSiteEdit = (data: any) => request.post('/template.amazonSite/edit', data)
// 站点新增
export const apiTemplateAmazonSiteAdd = (data: any) => request.post('/template.amazonSite/add', data)
// 站点删除
export const apiTemplateAmazonSiteDel = (data: any) => request.post('/template.amazonSite/del', data)
// 站点模板
export const apiTemplateAmazonSiteSiteTemplate = (params: any) => request.get('/template.amazonSite/siteTemplate', { params })
// 站点模板/添加
export const apiTemplateAmazonSiteAddTemplate = (data: any) => request.post('/template.amazonSite/addTemplate', data)
// 站点模板/编辑
export const apiTemplateAmazonSiteEditTemplate = (data: any) => request.post('/template.amazonSite/editTemplate', data)
// 站点模板/删除
export const apiTemplateAmazonSiteDelTemplate = (data: any) => request.post('/template.amazonSite/delTemplate', data)
// 站点模板/详情
export const apiTemplateAmazonSiteTemplateDetail = (params: any) => request.get('/template.amazonSite/templateDetail', { params })
// 站点模板/列表
export const apiTemplateAmazonSiteTemplateLists = (params: any) => request.get('/template.amazonSite/templateLists', { params })

// 汇率
// 汇率列表
export const apiSettingsSystemRateLists = (params: any) => request.get('/settings.systemRate/lists', { params })
// 手动刷新汇率
export const apiSettingsSystemRateUpdate = (params: any) => request.get('/settings.systemRate/update', { params })
// 删除汇率
export const apiSettingsSystemRateDel = (data: any) => request.post('/settings.systemRate/del', data)
// 新增货币汇率
export const apiSettingsSystemRateAdd = (data: any) => request.post('/settings.systemRate/add', data)
// 编辑货币汇率
export const apiSettingsSystemRateEdit = (data: any) => request.post('/settings.systemRate/edit', data)




